<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Cadastro de Pagamento</h4>
        </div>

        <div class="d-flex justify-content-end mb-2">
          <b-button variant="primary" @click="openRegisterPaymentFormModal">
            NOVO MÉTODO DE PAGAMENTO
          </b-button>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table :items="formList" :fields="formField" bordered striped hover>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                @click="openEditPaymentFormModal(row.item)"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>

              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                @click="openListDeadlineModal(row.item)"
              >
                <b-icon icon="list"></b-icon>
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                @click="openDeactivateModal(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-card-text>

        <payment-form-info
          v-model="registerPaymentFormModal"
          :key="registerPaymentFormModal"
          :formInfoObject="formInfo"
          @create-payment="registerPaymentForm"
          @update-payment="updatePaymentForm"
        />

        <b-modal
          v-model="listDeadlineModal"
          title="Gerenciar prazos"
          size="xl"
          hide-footer
        >
          <div class="d-flex justify-content-start mb-3">
            <h6>Adicionar novo prazo:</h6>
          </div>

          <b-form @submit.prevent="addDeadline">
            <form-input
              v-model="deadlineInfo.description"
              required
              icon="pencil"
              label="Descrição"
              :errors="errors['description']"
            />

            <b-row>
              <b-col>
                <form-number
                  v-model="deadlineInfo.daysBetweenInstallments"
                  label="Dias entre as parcelas"
                  icon="calendar2-check"
                  :errors="errors['daysBetweenInstallments']"
                  onlyNumber
                  required
                />
              </b-col>

              <b-col>
                <form-number
                  v-model="deadlineInfo.maxQuantityInstallments"
                  label="Quantidade maxima de parcelas"
                  icon="clipboard-plus"
                  :errors="errors['maxQuantityInstallments']"
                  onlyNumber
                  required
                />
              </b-col>

              <b-col>
                <form-number
                  v-model="deadlineInfo.minimumValue"
                  required
                  icon="cash-coin"
                  label="Valor mínimo"
                  :errors="errors['minimumValue']"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox v-model="deadlineInfo.display" :value="true">
                  Exibir
                </b-form-checkbox>
              </b-col>

              <b-col>
                <div class="d-flex justify-content-end mb-3">
                  <b-button variant="primary" type="submit">ADICIONAR</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>

          <div class="d-flex justify-content-start mb-3">
            <h6>Lista de prazos:</h6>
          </div>

          <b-table :items="deadlineList" :fields="deadlineField">
            <template #cell(description)="row"
              >{{ row.item.edit ? "" : row.item.description }}
              <form-input
                v-model="row.item.description"
                v-if="row.item.edit"
                :errors="errorsTable['description']"
                required
              />
            </template>
            <template #cell(daysBetweenInstallments)="row">
              {{ row.item.edit ? "" : row.item.daysBetweenInstallments }}
              <form-number
                v-model="row.item.daysBetweenInstallments"
                v-if="row.item.edit"
                :errors="errorsTable['daysBetweenInstallments']"
                required
              />
            </template>
            <template #cell(maxQuantityInstallments)="row"
              >{{ row.item.edit ? "" : row.item.maxQuantityInstallments }}
              <form-number
                v-model="row.item.maxQuantityInstallments"
                v-if="row.item.edit"
                :errors="errorsTable['maxQuantityInstallments']"
                required
              />
            </template>
            <template #cell(minimumValue)="row"
              >{{ row.item.edit ? "" : formatCash(row.item.minimumValue) }}
              <form-number
                v-model="row.item.minimumValue"
                v-if="row.item.edit"
                :errors="errorsTable['minimumValue']"
                required
              />
            </template>

            <template #cell(actions)="row">
              <b-button
                v-if="!row.item.edit"
                size="sm"
                variant="primary"
                class="mr-1"
                @click="editDeadlineFunction(row.item)"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>

              <b-button
                v-if="row.item.edit"
                size="sm"
                variant="success"
                class="mr-1"
                @click="updateDeadline(row.item)"
              >
                <b-icon
                  :icon="spin ? 'hourglass-split' : 'check'"
                  :animation="spin ? 'spin' : ''"
                ></b-icon>
              </b-button>

              <b-button
                :variant="row.item.display ? 'success' : 'danger'"
                size="sm"
                class="mr-1"
                @click="showDeadline(row.item)"
              >
                <b-icon :icon="row.item.display ? 'eye' : 'eye-slash'"></b-icon>
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                @click="deactivateDeadline(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-modal>

        <b-modal v-model="deactivateModal" title="Desativar" hide-footer>
          <p>Deseja desativar o cadastro de pagamento?</p>
          <div class="d-flex justify-content-end">
            <b-button @click="deactivatePaymentForm" variant="danger">
              DESATIVAR
            </b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import { formatMoney } from "../../utils/formatMoney";

import PaymentFormInfo from "../../components/payment/PaymentFormInfo.vue";
import FormInput from "../../components/form/FormInput.vue";
import FormNumber from "../../components/form/FormNumber.vue";
import http from "../../plugins/http";

export default {
  components: { PaymentFormInfo, FormInput, FormNumber },
  data() {
    return {
      formList: [],
      deactivateModal: false,
      listDeadlineModal: false,
      registerPaymentFormModal: false,
      spin: false,
      paymentFormId: null,
      formInfo: {
        fiscalCode: "",
        description: "",
        checkLimit: false,
      },
      deadlineInfo: {
        description: "",
        daysBetweenInstallments: "",
        maxQuantityInstallments: "",
        minimumValue: "",
        display: false,
      },
      deadlineField: [
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "daysBetweenInstallments",
          label: "Dias entre as parcelas",
        },
        {
          key: "maxQuantityInstallments",
          label: "Quantidade maxima de parcelas",
        },
        {
          key: "minimumValue",
          label: "Valor mínimo",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      deadlineList: [],
      formField: [
        {
          key: "description",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "fiscalCode",
          label: "Código Fiscal",
          sortable: true,
          thStyle: { width: "140px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "140px !important" },
        },
      ],
      errors: {},
      errorsTable: {},
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    openRegisterPaymentFormModal() {
      this.formInfo = {
        description: "",
        fiscalCode: "",
        checkLimit: false,
      };
      this.registerPaymentFormModal = true;
    },

    openEditPaymentFormModal(item) {
      this.registerPaymentFormModal = true;
      this.formInfo = item;
    },

    openDeactivateModal(item) {
      this.deactivateModal = true;
      this.paymentFormId = item.id;
    },

    editDeadlineFunction(item) {
      this.errorsTable = {};
      const index = this.deadlineList.findIndex(
        (element) => element.id === item.id
      );

      this.deadlineList.splice(index, 1, { ...item, edit: true });
    },

    formatCash(item) {
      return formatMoney(item);
    },

    async showDeadline(item) {
      const { data } = await http.put(`payment/${item.id}/showDeadline`, {
        show: !item.display,
      });
      const index = this.deadlineList.findIndex(
        (element) => element.id === item.id
      );

      this.deadlineList.splice(index, 1, data);
    },

    async deactivateDeadline(item) {
      await http.delete(`payment/${item.id}/deadline`);
      const newList = this.deadlineList.filter(
        (deadline) => deadline.id !== item.id
      );
      this.deadlineList = newList;
    },

    async openListDeadlineModal(item) {
      this.deadlineInfo = {
        description: "",
        daysBetweenInstallments: "",
        maxQuantityInstallments: "",
        minimumValue: "",
        display: false,
      };
      this.paymentFormId = item.id;
      this.errors = {};
      this.errorsTable = {};

      const { data } = await http(`payment/${this.paymentFormId}/deadline`);
      this.deadlineList = data;
      this.listDeadlineModal = true;
    },

    async addDeadline() {
      try {
        this.errors = {};
        const { data } = await http.post(
          `payment/${this.paymentFormId}/deadline`,
          this.deadlineInfo
        );
        this.deadlineList.push(data);
        this.deadlineInfo = {
          description: "",
          daysBetweenInstallments: "",
          maxQuantityInstallments: "",
          minimumValue: "",
          display: false,
        };
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async updateDeadline(item) {
      this.errorsTable = {};
      this.spin = true;
      try {
        const { data } = await http.put(`payment/${item.id}/deadline`, {
          description: item.description,
          daysBetweenInstallments: Number(item.daysBetweenInstallments),
          maxQuantityInstallments: Number(item.maxQuantityInstallments),
          minimumValue: item.minimumValue,
        });

        const index = this.deadlineList.findIndex((e) => e.id === item.id);
        this.deadlineList.splice(index, 1, data);
      } catch (error) {
        if (error.response.status === 406) {
          this.errorsTable = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
      this.spin = false;
    },

    registerPaymentForm(form) {
      this.formList.push(form);
    },

    updatePaymentForm(form) {
      const index = this.formList.findIndex((e) => e.id === form.id);
      this.formList.splice(index, 1, form);
      this.registerPaymentFormModal = false;
    },

    async deactivatePaymentForm() {
      this.deactivateModal = false;
      this.setOverlay(true);
      await http.put(`/payment/${this.paymentFormId}/deactivate`);
      const newArray = this.formList.filter(
        (form) => form.id !== this.paymentFormId
      );
      this.formList = newArray;
      this.setOverlay(false);
    },
  },

  async created() {
    this.setOverlay(true);
    const { data } = await http(`/payment/`);
    this.formList = data;
    this.setOverlay(false);
  },
};
</script>
