<template>
  <form-input
    :value="value"
    :label="label"
    :icon="icon"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    :errors="errors"
    @input="$emit('input', $event)"
    @keypress="isNumber($event)"
    @enter="$emit('enter', $event)"
  />
</template>
<script>
import FormInput from "./FormInput.vue";
export default {
  components: { FormInput },
  name: "FormNumber",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
    },
    spellcheck: {
      type: Boolean,
      required: false,
    },
    copyable: {
      type: Boolean,
      required: false,
    },
    rotateIcon: {
      type: String,
      required: false,
    },
    onlyDot: {
      type: Boolean,
      required: false,
    },
    onlyNumber: {
      type: Boolean,
      required: false,
    },
    limit: {
      type: Number,
      required: false,
    },
  },
  methods: {
    isNumber(event) {
      const keysAlowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ",",
        ".",
      ];

      if (this.onlyDot) {
        keysAlowed.splice(keysAlowed.indexOf(","), 1);
      }

      if (this.onlyNumber) {
        keysAlowed.splice(keysAlowed.indexOf(","), 2);
        if (this.onlyDot) {
          keysAlowed.push(".");
        }
      }

      if (this.value.toString().replace(/[^0-9]/g, "").length == this.limit) {
        event.preventDefault();
      }

      const keyPressed = event.key;
      if (!keysAlowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
  },
};
</script>
