<template>
  <b-modal
    :visible="visible"
    :title="
      formInfoObject.id ? 'Edite as informações' : 'Informe o novo método'
    "
    size="lg"
    hide-footer
    @change="$emit('change', false)"
  >
    <b-form @submit.prevent="registerPaymentForm">
      <b-row>
        <b-col md="3">
          <form-number
            v-model="formToChange.fiscalCode"
            label="Código Fiscal"
            placeholder="Código fiscal da forma de pagamento"
            icon="cash-coin"
            :limit="2"
            :errors="errors['fiscalCode']"
            onlyNumber
            required
          />
        </b-col>

        <b-col>
          <form-input
            v-model="formToChange.description"
            label="Descrição"
            icon="pencil"
            placeholder="Descrição da forma de pagamento"
            :errors="errors['description']"
            required
          />
        </b-col>
      </b-row>
      <b-form-checkbox v-model="formToChange.checkLimit" :value="true">
        Verificar Limite
      </b-form-checkbox>
      <div class="d-flex justify-content-end mb-2">
        <b-button variant="primary" type="submit">SALVAR</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import { mapMutations } from "vuex";
import FormInput from "../form/FormInput.vue";
import FormNumber from "../form/FormNumber.vue";
import http from "../../plugins/http";

export default {
  components: { FormInput, FormNumber },
  model: {
    event: "change",
    prop: "visible",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    formInfoObject: {
      type: Object,
      default: () => ({
        fiscalCode: "",
        description: "",
        checkLimit: false,
      }),
    },
  },
  data() {
    return {
      errors: {},
      formToChange: {
        ...this.formInfoObject,
      },
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    async registerPaymentForm() {
      this.errors = {};
      try {
        const { formToChange: form } = this;
        if (!form.id) {
          const { data } = await http.post(`/payment/new`, {
            checkLimit: form.checkLimit,
            fiscalCode: form.fiscalCode,
            description: form.description,
          });
          this.$emit("create-payment", data);
        } else {
          const { data } = await http.put(`/payment/${form.id}`, {
            checkLimit: form.checkLimit,
            fiscalCode: form.fiscalCode,
            description: form.description,
          });
          this.$emit("update-payment", data);
        }
        this.$emit("change", false);
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },
  },
};
</script>
